// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/debug.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/debug.tsx");
  import.meta.hot.lastModified = "1699623608307.243";
}
// REMIX HMR END

//import { usePresenceWithCursors } from "~/presence/use-cursors";
import { usePresence } from "~/presence/presence-context";
export default function Debug() {
  _s();
  /*const { myId, myself } = usePresenceWithCursors((state) => ({
    myId: state.myId,
    myself: state.myself,
  }));*/
  const {
    myId,
    myself
  } = usePresence(state => ({
    myId: state.myId,
    myself: state.myself
  }));
  return <div className="text-xs text-gray-70">
      <p>
        <b>Current user</b>
      </p>
      <ul>
        <li>ID: {myId}</li>
        <li>
          <pre>{JSON.stringify(myself, null, 2)}</pre>
        </li>
      </ul>
    </div>;
}
_s(Debug, "xM8kTr9Vusd8xXcAjw8AUO4L3Kw=", false, function () {
  return [usePresence];
});
_c = Debug;
var _c;
$RefreshReg$(_c, "Debug");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;