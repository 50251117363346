// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/room.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/room.tsx");
  import.meta.hot.lastModified = "1699630232992.4285";
}
// REMIX HMR END

import SetMessage from "~/components/set-message";
import Debug from "./debug";
import OtherCursors from "~/presence/other-cursors";
import Facepile from "./facepile";
import useCursorTracking from "~/presence/use-cursors";
export default function Room() {
  _s();
  // defaults to tracking cursors within the "window" reference frame. Can also be "document"
  useCursorTracking("document");
  return <div className="flex flex-col gap-4">
      <Facepile />
      <SetMessage />
      <Debug />
      <OtherCursors />
    </div>;
}
_s(Room, "bwqMHW/yicCAJX1WamkShzG7HV8=", false, function () {
  return [useCursorTracking];
});
_c = Room;
var _c;
$RefreshReg$(_c, "Room");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;