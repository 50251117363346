// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/presence/presence-context.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/presence/presence-context.tsx");
  import.meta.hot.lastModified = "1699625587453.0962";
}
// REMIX HMR END

/*
TODO:

- track cursor position only within a provided element
- use msgpack
- provide a default room name from the href
*/

import { useEffect, createContext } from "react";
import usePartySocket from "partysocket/react";
import { create } from "zustand";
import { decodeMessage, encodeClientMessage, partyMessageSchema } from "party/presence-schema";
export const usePresence = create(set => ({
  myId: null,
  myself: null,
  setMyId: myId => set({
    myId
  }),
  pendingUpdate: null,
  clearPendingUpdate: () => set({
    pendingUpdate: null
  }),
  updatePresence: partial => set(state => {
    // Optimistically update myself, and also set a pending update
    // Can only be used once myself has been set
    if (!state.myself) return {};
    const presence = {
      ...state.myself.presence,
      ...partial
    };
    const myself = {
      ...state.myself,
      presence
    };
    return {
      myself,
      pendingUpdate: partial
    };
  }),
  otherUsers: new Map(),
  setUsers: users => set(state => {
    let otherUsers = new Map();
    users.forEach((user, id) => {
      if (id === state.myId) return;
      otherUsers.set(id, user);
    });
    const myself = state.myId ? users.get(state.myId) : null;
    return {
      myself,
      otherUsers
    };
  }),
  addUser: (id, user) => {
    set(state => {
      if (id === state.myId) {
        return {
          myself: user
        };
      }
      const otherUsers = new Map(state.otherUsers);
      otherUsers.set(id, user);
      return {
        otherUsers
      };
    });
  },
  removeUser: id => {
    set(state => {
      if (id === state.myId) {
        return {
          myself: null
        };
      }
      const otherUsers = new Map(state.otherUsers);
      otherUsers.delete(id);
      return {
        otherUsers
      };
    });
  },
  updateUser: (id, presence) => {
    set(state => {
      if (id === state.myId && state.myself !== null) {
        return {
          myself: {
            ...state.myself,
            presence
          }
        };
      }
      const otherUsers = new Map(state.otherUsers);
      const user = otherUsers.get(id);
      if (!user) return {
        otherUsers
      };
      otherUsers.set(id, {
        ...user,
        presence
      });
      return {
        otherUsers
      };
    });
  }
}));
export const PresenceContext = createContext({});
export default function PresenceProvider(props) {
  _s();
  const {
    setMyId,
    setUsers,
    addUser,
    updateUser,
    removeUser,
    pendingUpdate,
    clearPendingUpdate
  } = usePresence();
  const updateUsers = message => {
    if (message.type !== "changes") return;
    if (message.add) {
      for (const [id, user] of Object.entries(message.add)) {
        addUser(id, user);
      }
    }
    if (message.presence) {
      for (const [id, presence] of Object.entries(message.presence)) {
        updateUser(id, presence);
      }
    }
    if (message.remove) {
      for (const id of message.remove) {
        removeUser(id);
      }
    }
  };
  const handleMessage = async event => {
    //const message = JSON.parse(event.data) as PartyMessage;
    const data = event.data instanceof Blob ?
    // byte array -> msgpack
    decodeMessage(await event.data.arrayBuffer()) :
    // string -> json
    JSON.parse(event.data);
    const result = partyMessageSchema.safeParse(data);
    if (!result.success) return;
    const message = result.data;
    switch (message.type) {
      case "sync":
        // create Map from message.users (which is id -> User)
        setUsers(new Map(Object.entries(message.users)));
        break;
      case "changes":
        updateUsers(message);
        break;
    }
  };
  const socket = usePartySocket({
    host: props.host,
    party: "presence",
    room: props.room,
    onMessage: event => handleMessage(event)
  });

  // Send "join" message when the socket connects
  useEffect(() => {
    if (socket) {
      setMyId(socket.id);
      const message = {
        type: "join",
        presence: props.presence
      };
      socket.send(encodeClientMessage(message));
    }
  }, [props.presence, socket]);
  useEffect(() => {
    if (!pendingUpdate) return;
    if (!socket) return;
    const message = {
      type: "update",
      presence: pendingUpdate
    };
    socket.send(encodeClientMessage(message));
    clearPendingUpdate();
  }, [socket, pendingUpdate]);
  return <PresenceContext.Provider value={{}}>
      {props.children}
    </PresenceContext.Provider>;
}
_s(PresenceProvider, "wzgvj8tHNW6kV2hBE0fvY4dZmg0=", false, function () {
  return [usePresence, usePartySocket];
});
_c = PresenceProvider;
var _c;
$RefreshReg$(_c, "PresenceProvider");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;