// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/_index.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/_index.tsx");
  import.meta.hot.lastModified = "1699625599060.1245";
}
// REMIX HMR END

import { useLoaderData } from "@remix-run/react";
import Room from "~/components/room";
import PresenceProvider from "~/presence/presence-context";
export const meta = () => {
  return [{
    title: "New Partymix App"
  }, {
    name: "description",
    content: "Welcome to Remix!"
  }];
};

// PartyKit will inject the host into the server bundle
// so let's read it here and expose it to the client
export function loader({
  // @ts-ignore - TODO
  context
}) {
  return {
    partykitHost: PARTYKIT_HOST,
    ...context
  };
}
export default function Index() {
  _s();
  const {
    partykitHost
  } = useLoaderData();
  return <div className="p-4 flex flex-col gap-4" style={{
    fontFamily: "system-ui, sans-serif",
    lineHeight: "1.8"
  }}>
      <h1>sketch-presence</h1>
      <PresenceProvider host={partykitHost} room="default" presence={{
      cursor: null,
      message: null,
      name: "Anonymous User",
      color: "#0000f0"
    }}>
        <Room />
      </PresenceProvider>
    </div>;
}
_s(Index, "6dU/y19IOBvBWYWmZ6/W1oe5w14=", false, function () {
  return [useLoaderData];
});
_c = Index;
var _c;
$RefreshReg$(_c, "Index");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;