// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/presence/other-cursors.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/presence/other-cursors.tsx");
  import.meta.hot.lastModified = "1699624802272.1023";
}
// REMIX HMR END

import Cursor from "./cursor";
import { usePresenceWithCursors } from "./use-cursors";
export default function OtherCursors() {
  _s();
  const otherUserIds = usePresenceWithCursors(state => Array.from(state.otherUsers.keys()));
  const within = usePresenceWithCursors(state => state.within);
  return <div className={`${within === "window" ? "fixed" : "absolute"} top-0 left-0 w-full h-full pointer-events-none`}>
      {otherUserIds.map(id => {
      return <Cursor key={id} userId={id} fill={"#00f"} />;
    })}
    </div>;
}
_s(OtherCursors, "aNrK22j01ZeLGKES+E7KX2QBcBI=", false, function () {
  return [usePresenceWithCursors, usePresenceWithCursors];
});
_c = OtherCursors;
var _c;
$RefreshReg$(_c, "OtherCursors");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;