// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/set-message.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/set-message.tsx");
  import.meta.hot.lastModified = "1699623623426.3494";
}
// REMIX HMR END

import { useState } from "react";
import { usePresence } from "~/presence/presence-context";
export default function SetMessage() {
  _s();
  const [myself, updatePresence] = usePresence(state => [state.myself, state.updatePresence]);
  const [message, setMessage] = useState(myself?.presence?.message ?? "");
  const handleSubmit = event => {
    event.preventDefault();
    if (!message) return;
    updatePresence({
      message
    });
  };
  const handleClear = () => {
    setMessage("");
    updatePresence({
      message: null
    });
  };
  return <form className="flex justify-start items-center gap-2" onSubmit={handleSubmit}>
      <label htmlFor="message">Cursor message</label>
      <input type="text" name="message" placeholder="e.g. Hello, World!" value={message} onChange={event => setMessage(event.target.value)} className="border border-gray-300 p-1" />
      <input type="submit" value="Set" className="bg-gray-100 hover:bg-gray-200 border border-gray-300 rounded-sm px-3 py-1" />
      <input type="button" value="Clear" onClick={handleClear} className="bg-gray-100 hover:bg-gray-200 border border-gray-300 rounded-sm px-3 py-1" />
    </form>;
}
_s(SetMessage, "XTCCzRICiyxlDkYEk3yI16RTajY=", false, function () {
  return [usePresence];
});
_c = SetMessage;
var _c;
$RefreshReg$(_c, "SetMessage");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;