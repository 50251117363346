// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/facepile.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/facepile.tsx");
  import.meta.hot.lastModified = "1699623615179.201";
}
// REMIX HMR END

import { usePresence } from "~/presence/presence-context";
function Avatar() {
  const initials = "XX";
  return <div className="outline rounded-full flex justify-center items-center w-10 h-10 bg-green-400 outline-green-600 outline-1">
      {initials}
    </div>;
}
_c = Avatar;
export default function Facepile() {
  _s();
  const {
    myId,
    myself,
    otherUsers
  } = usePresence();
  return <div className="absolute top-0 right-0 p-4 justify-end flex space-x-4">
      <div className="flex justify-end flex flex-row -space-x-2">
        {Array.from(otherUsers.entries()).map(([id, user]) => <Avatar key={id} />)}
      </div>
      <Avatar key={myId} />
    </div>;
}
_s(Facepile, "YL1JJbpr57JoZSZ//XGXKUk/h38=", false, function () {
  return [usePresence];
});
_c2 = Facepile;
var _c, _c2;
$RefreshReg$(_c, "Avatar");
$RefreshReg$(_c2, "Facepile");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;